@keyframes rotation {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rev-rotation {
  from {
    transform: rotate(359deg)
  }
  to {
    transform: rotate(0deg);
  }
  
}

.App {
  font-family: 'Roboto Mono', monospace;
  text-align: center;
}

/* Desktop resolution */
@media screen and (min-device-width: 1225px){
  
  .About-Me {
    display: flex;
    position: absolute;
    top: 3%;
    bottom: 2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 5%;
  }
  
  #About-Me__Text {
    overflow-y: auto;
  }
  
  .Contact-Me__List-Item {
    list-style: none;
  }
  
  .Header {
    display: flex;
    position: fixed;
    top: 0%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 2px solid blue;
    box-shadow: 0 0 .7rem darkblue;
    z-index: 2;
    background-color: white;
  }
  
  .Header-Button {
    user-select: none;
    padding-left: .2rem;
    padding-right: .2rem;
    min-width: max-content;
    background-color: transparent;
    transition: .4s;
    z-index: 1;
  }
  
  .Header-Button:hover {
    background-color: blue;
    color: whitesmoke;
    cursor: pointer;
  }
  
  .Home {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    justify-content: center;
    min-height: 100%;
  }
  
  .Home__Header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(224, 221, 255, 0.5);
    border-radius: 50%;
    margin: 3rem;
    padding: 3rem;
    z-index: -1;
  }
  
  .Home__ImageWheel {
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  #reactImage {
    animation: rev-rotation 3s infinite linear;
  }
  
  .ImageWheel {
    position: fixed;
    display: grid;
    height: 450px;
    width: 450px;
    top: 40%;
    animation: rotation 12s infinite linear;
    align-items: center;
    justify-content: center;
    grid-template-columns:repeat(2, 1fr);
  }
  
  .ImageWheel__Item {
    user-select: none;
    animation: rev-rotation 12s infinite linear;
    width: 150px;
    height: 150px;
    margin: auto;
    z-index: 1;
  }
  
  #image__headshot {
    border-radius: 50%;
    width: 15rem;
    margin: 1rem;
  }
  
  .Main {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0%;
    left: 0%;
    min-height: 100vh;
    max-height: 100%;
  }
  
  .My-Projects {
    position: absolute;
    top: 5%;
    max-width: 90%;
  }
  
  #Projects-Bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  
  .Projects-Bar__Item {
    user-select: none;
    font-weight: bold;
    margin: 1rem;
    transition: .6s;
    outline: transparent solid 2px;
  }
  
  .Projects-Bar__Item:hover {
    outline: blue solid 2px;
    cursor: pointer;
  }
  
  .Project-Panel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Social-Link {
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    transition: .2s;
  }
  
  .Social-Link:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 2px 1px;
    border-radius: 3px;
  }
  
  .Social-Links__Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

/* Smaller than desktop resolution. Check for Orientation */
@media screen and (max-device-width: 1224px) {


  /* Portrait orientation */
  @media (orientation: portrait){

  }
  /* Landscape Orientation */
  @media (orientation: landscape){
  
  }
}

